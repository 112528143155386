import { combineReducers, AnyAction } from 'redux';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import userSessionReducer from './userSessionSlice';
import farmsReducer from './farmsSlice';
import farmDetailsReducer from './farmDetailsReducer';
import redirectReducer from './redirectReducer';
import preloaderReducer from './preloaderReducer';
import alarmEntityOverviewDetailsReducer from './alarmEntityOverviewDetailsReducer';
import eventDetailsSlice from './eventDetailsSlice';
import domainsReducer from './domainsReducer';
import componentsDataReducer from './componentsDataReducer';
import entityInformationReducer from './entityInformationReducer';
import entitySettingsReducer from './entitySettingsSlice';
import entitySettingsOptionsReducer from './entitySettingsOptionsSlice';
import updateEntityNameReducer from './updateEntityNameReducer';
import alarmsLevelsReducer from './alarmsLevelsReducer';
import eventsLevelsSlice from './eventsLevelsSlice';
import updateHistorySlice from './updateHistorySlice';
import eventTypesReducer from './eventTypesReducer';
import canbusTroubleshootReducer from './canbusTroubleshootReducer';
import canbusTroubleshootTutorialReducer from './canbusTroubleshootTutorialReducer';
import entityStatusReducer from './entityStatusReducer';
import loadManagerReducer from './loadManagerReducer';
import entityKpiDetailsReducer from './entityKpiDetailsReducer';
import domainModalGridReducer from './domainModalGridReducer';
import applicationVersionReducer from './applicationVersionReducer';
import dataChartReducer from './dataChartReducer';
import dataChartEventsReducer from './dataChartEventsReducer';
import dataConfigChartReducer from './dataConfigChartReducer';
import vectorDataChartSlice from './vectorDataChartSlice';
import vectorConfigurationReducer from './vectorConfigurationSlice';
import featureFlagsReducer from './featureFlagSlice';
import eventLevelMenuOptionsReducer from './eventsLevelMenuOptionsReducer';
import {
  RESET_STORAGE,
  RESET_STORAGE_WITHOUT_USER_SESSION,
} from '../common/types';
import vectorDetailsReducer from './vectorDetailsSlice';
import zoomReducer from './zoomSlice';
import vectorCriticalAlarmsAndEventsReducer from './vectorCriticalAlarmsAndEventsSlice';
import farmsOptionsReducer from './farmsOptionsSlice';
import astronautPropertySelectReducer from './astronautPropertySelectSlice';
import fencesStateReducer from './fencesStateSlice';
import appliedPropertiesReducer from './appliedPropertiesSlice';
import tooltipReducer from './tooltipSlice';
import lineChartConfigReducer from './lineChartConfigSlice';
import colorDictionaryReducer from './colorDictionarySlice';
import updatesReducer from './updatesSlice';
import networkErrorReducer from './networkErrorSlice';
import dataExplorerLoadManagerReducer from './dataExplorerLoadManagerSlice';
import canvasGenerationReducer from './canvasGenerationSlice';
import astronautTooltipReducer from './astronautTooltipSlice';
import astronautSelectedActivityDetailsReducer from './astronautSelectedActivityDetailsSlice';
import verticalLineReducer from './verticalLineSlice';
import entityBackupSettingsReducer from './entityBackupSettingsSlice';
import astronautGraphElementsReducer from './astronautGraphElementsSlice';
import kpiReducer from './kpiDataSlice';
import interceptorsReducer from './interceptorsSlice';
import vectorGraphElementsReducer from './vectorGraphElementsSlice';
import aggregationSlice from './aggregationSlice';
import logsSlice from './logsSlice';
import compositeStatesReducer from './compositeStatesSlice';
import translationSlice from './translationSlice';

/** Combine all reducers
 * @returns {Object} store
 */

export const appReducer = combineReducers({
  userSession: userSessionReducer,
  farms: farmsReducer,
  farmDetails: farmDetailsReducer,
  redirect: redirectReducer,
  preloader: preloaderReducer,
  alarmOverviewDetails: alarmEntityOverviewDetailsReducer,
  eventPageDetails: eventDetailsSlice,
  domainsData: domainsReducer,
  selectedDomainComponentsData: componentsDataReducer,
  entityInfo: entityInformationReducer,
  entitySettings: entitySettingsReducer,
  entitySettingsOptions: entitySettingsOptionsReducer,
  updateEntityName: updateEntityNameReducer,
  alarmsLevels: alarmsLevelsReducer,
  eventTypes: eventTypesReducer,
  eventsPageLevels: eventsLevelsSlice,
  canbusTroubleshoot: canbusTroubleshootReducer,
  canbusTroubleshootTutorial: canbusTroubleshootTutorialReducer,
  entityStatus: entityStatusReducer,
  entityBackupSettings: entityBackupSettingsReducer,
  loadManager: loadManagerReducer,
  entityKpi: entityKpiDetailsReducer,
  domainModalGrid: domainModalGridReducer,
  applicationVersion: applicationVersionReducer,
  dataCharts: dataChartReducer,
  dataConfigCharts: dataConfigChartReducer,
  dataExplorerLoadManager: dataExplorerLoadManagerReducer,
  vectorDataCharts: vectorDataChartSlice,
  vectorConfiguration: vectorConfigurationReducer,
  featureFlag: featureFlagsReducer,
  translate: translationSlice,
  eventsChartData: dataChartEventsReducer,
  eventsLevelMenuOption: eventLevelMenuOptionsReducer,
  compositeStatesData: compositeStatesReducer,
  updateHistoryData: updateHistorySlice,
  vectorDetails: vectorDetailsReducer,
  zoom: zoomReducer,
  vectorCriticalAlarmsAndEvents: vectorCriticalAlarmsAndEventsReducer,
  farmsOptions: farmsOptionsReducer,
  astronautPropertySelect: astronautPropertySelectReducer,
  fencesState: fencesStateReducer,
  appliedProperties: appliedPropertiesReducer,
  tooltip: tooltipReducer,
  lineChartConfig: lineChartConfigReducer,
  colorDictionary: colorDictionaryReducer,
  updates: updatesReducer,
  canvasGeneration: canvasGenerationReducer,
  networkError: networkErrorReducer,
  astronautTooltip: astronautTooltipReducer,
  astronautSelectedActivity: astronautSelectedActivityDetailsReducer,
  verticalLine: verticalLineReducer,
  graphElements: astronautGraphElementsReducer,
  kpi: kpiReducer,
  interceptors: interceptorsReducer,
  vectorGraphElements: vectorGraphElementsReducer,
  aggregation: aggregationSlice,
  logs: logsSlice,
});

export type RootState = ReturnType<typeof appReducer>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const rootReducer = (
  state: any,
  action: AnyAction,
): ReturnType<typeof appReducer> => {
  // Reset all items in the storage
  if (action.type === RESET_STORAGE) {
    // Remove all data from store except application version
    const { applicationVersion, featureFlag } = state;
    // eslint-disable-next-line no-param-reassign
    state = {
      applicationVersion,
      featureFlag,
      interceptors: { status: 'initialized' },
    };
  }
  // Reset all items in storage except userSession
  if (action.type === RESET_STORAGE_WITHOUT_USER_SESSION) {
    const { userSession, applicationVersion } = state;
    // Keep userSession and applicationVersion in store
    // eslint-disable-next-line no-param-reassign
    state = {
      userSession,
      applicationVersion,
      interceptors: { status: 'initialized' },
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
