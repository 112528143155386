import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../reducers/rootReducer';
import { PREFERRED_UNIT } from '../../constants';
import './UserDialog.scss';
import { setUserPreference } from '../../../reducers/userSessionSlice';
import DialogComponent from '../../../components/Dialog/DialogComponent';
import UserDialogContent from './UserDialogContent';
import { updateLanguage } from '../../../reducers/translationSlice';

function UserDialog({ open, setOpen }) {
  const dispatch = useDispatch();
  const [unit, setUnit] = React.useState<string>('');

  const { accessToken } = useAppSelector((state) => state.userSession);
  const { preferredLanguage } = useAppSelector((state) => state.translate);

  const [language, setLanguage] = React.useState(preferredLanguage);

  const onSave = () => {
    dispatch(updateLanguage({ accessToken, language }));
    dispatch(setUserPreference(unit));
    localStorage.setObject(PREFERRED_UNIT, unit);
    setOpen(false);
    // setTimeout is used because of Firefox browser which can not detect that the language state is changed
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };
  const onClose = () => {
    setLanguage(preferredLanguage);
    setOpen(false);
  };

  return (
    <DialogComponent
      title="User settings"
      content={
        <UserDialogContent
          language={language}
          setLanguage={setLanguage}
          unit={unit}
          setUnit={setUnit}
        />
      }
      rightButton="Save"
      rightAction={onSave}
      leftButton="Cancel"
      leftActionProp={onClose}
      className="user-settings"
      setOpen={setOpen}
      open={open}
      disabledButton={false}
    />
  );
}

export default UserDialog;
