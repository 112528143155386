import {
  PayloadAction,
  createAction,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { format, startOfYesterday, subYears } from 'date-fns';
import type {
  FeedFencesYesterdayAndAverageType,
  FeedTypeYesterdayAndAverageType,
  KPIDataResponseType,
  KPIDataType,
  KPIRequestParams,
  KPIYesterdayAndAverageType,
  KpiType,
  RationsYesterdayAndAverageType,
  SelectedKPI,
  WeightsByNameYesterdayAndAverageType,
} from './types/kpiDataTypes';
import type { Nullable, PerformanceIndicatorsStorage } from '../common/types';
import { RootState } from './rootReducer';
import {
  formatFeedFencesKPI,
  formatFeedTypeKPI,
  formatKPI,
  formatRationKPI,
  formatRationWeightsByName,
} from '../components/PerformanceIndicators/utils';
import { getSearchParams, updateSearchParams } from '../common/utils';
import { expandedSectors, performanceIndicators } from '../common/constants';

export type KPIDataInitialState = {
  menu: Nullable<KPIDataResponseType['menu']>;
  kpiData: Nullable<KPIDataResponseType['data']>;
  timeFrameDomain: Nullable<Date[]>;
  currentSelectedKPI: Nullable<SelectedKPI>;
  hasError: boolean;
  defaultDomain: Date[];
  expandedSectors: Nullable<string[]>;
};

export const initialState: KPIDataInitialState = {
  menu: null,
  kpiData: null,
  currentSelectedKPI: null,
  timeFrameDomain: null,
  hasError: false,
  defaultDomain: [subYears(startOfYesterday(), 1), startOfYesterday()],
  expandedSectors: null,
};

export const kpiSlice = createSlice({
  name: 'kpiSlice',
  initialState,
  reducers: {
    setKPIData: (state, action: PayloadAction<KPIDataResponseType>) => {
      const { data, menu, defaultDomain } = action.payload;
      state.menu = menu;
      state.kpiData = data;
      state.hasError = false;
      state.defaultDomain = defaultDomain;

      const urlExpandedSelectors = getSearchParams(expandedSectors);
      const lsExpandedSelectors = localStorage.getObject<
        PerformanceIndicatorsStorage,
        typeof performanceIndicators
      >(performanceIndicators);

      if (urlExpandedSelectors) {
        const splittedSelectors = urlExpandedSelectors
          .split(',')
          ?.filter((s) => s !== '');
        state.expandedSectors = splittedSelectors;
        localStorage.updateObjectProperty(
          performanceIndicators,
          expandedSectors,
          splittedSelectors,
        );
      } else if (lsExpandedSelectors) {
        state.expandedSectors = lsExpandedSelectors.expandedSectors;
        updateSearchParams(
          expandedSectors,
          state.expandedSectors.join(','),
          true,
        );
      } else {
        state.expandedSectors = Object.keys(menu);
        localStorage.setObject(performanceIndicators, {
          expandedSectors: state.expandedSectors,
        });
        updateSearchParams(
          expandedSectors,
          state.expandedSectors.join(','),
          true,
        );
      }
    },
    setKPIError: (state) => {
      state.hasError = true;
    },
    setKPITimeFrameDomain: (state, action: PayloadAction<Date[] | null>) => {
      state.timeFrameDomain = action.payload;
    },
    setCurrentSelectedKPI: (state, action: PayloadAction<SelectedKPI>) => {
      state.currentSelectedKPI = action.payload;
    },
    setExpandedSelector: (
      state,
      action: PayloadAction<{ title: string; expanded: boolean }>,
    ) => {
      const { title, expanded } = action.payload;

      state.expandedSectors = expanded
        ? state.expandedSectors
          ? [...state.expandedSectors, title]
          : [title]
        : (state.expandedSectors ?? []).filter((item) => item !== title);
    },
    setExpandedSelectors: (state, action: PayloadAction<string[]>) => {
      state.expandedSectors = action.payload;
    },
    resetKPIData: () => initialState,
  },
});

export const {
  setKPIData,
  setKPIError,
  resetKPIData,
  setExpandedSelector,
  setExpandedSelectors,
  setCurrentSelectedKPI,
  setKPITimeFrameDomain,
} = kpiSlice.actions;

export const kpiDataSelectors = {
  selectMenu: (state: RootState) => state.kpi.menu,
  selectData: (state: RootState) => state.kpi.kpiData,
  selectKPITimeFrameDomain: (state: RootState) => state.kpi.timeFrameDomain,
  selectCurrentSelectedKpi: (state: RootState) => state.kpi.currentSelectedKPI,
  selectIsCurrentKpiActive: (state: RootState, kpiId: string) =>
    state.kpi.currentSelectedKPI?.id === kpiId,
  selectHasError: (state: RootState) => state.kpi.hasError,
  defaultDomain: (state: RootState) => state.kpi.defaultDomain,
  isMenuExpanded: (state: RootState, title: string) =>
    !!state.kpi.expandedSectors?.includes(title),
};

export const selectFeedTypeYesterdayAndAverage = createSelector(
  [
    kpiDataSelectors.selectData,
    kpiDataSelectors.selectMenu,
    kpiDataSelectors.selectKPITimeFrameDomain,
    kpiDataSelectors.defaultDomain,
  ],
  (
    kpiData,
    menu,
    domain,
    defaultDomain,
  ): Nullable<FeedTypeYesterdayAndAverageType> => {
    if (kpiData && menu?.['Feed types']) {
      return formatFeedTypeKPI(
        kpiData,
        menu,
        domain !== null ? domain : defaultDomain,
        defaultDomain,
      );
    }

    return null;
  },
);

export const selectRationsYesterdayAndAverage = createSelector(
  [
    kpiDataSelectors.selectData,
    kpiDataSelectors.selectMenu,
    kpiDataSelectors.selectKPITimeFrameDomain,
    kpiDataSelectors.defaultDomain,
  ],
  (
    kpiData,
    menu,
    domain,
    defaultDomain,
  ): Nullable<RationsYesterdayAndAverageType> => {
    if (kpiData && menu?.Rations) {
      return formatRationKPI(
        kpiData,
        menu,
        domain !== null ? domain : defaultDomain,
        defaultDomain,
      );
    }

    return null;
  },
);

export const selectFeedFencesYesterdayAndAverage = createSelector(
  [
    kpiDataSelectors.selectData,
    kpiDataSelectors.selectMenu,
    kpiDataSelectors.selectKPITimeFrameDomain,
    kpiDataSelectors.defaultDomain,
  ],
  (
    kpiData,
    menu,
    domain,
    defaultDomain,
  ): Nullable<FeedFencesYesterdayAndAverageType> => {
    if (kpiData && menu?.['Feed fences']) {
      return formatFeedFencesKPI(
        kpiData,
        menu,
        domain !== null ? domain : defaultDomain,
        defaultDomain,
      );
    }

    return null;
  },
);

export const selectKPIYesterdayAndAverage = createSelector(
  [
    kpiDataSelectors.selectData,
    kpiDataSelectors.selectMenu,
    kpiDataSelectors.selectKPITimeFrameDomain,
    kpiDataSelectors.defaultDomain,
  ],
  (
    kpiData,
    menu,
    domain,
    defaultDomain,
  ): Nullable<KPIYesterdayAndAverageType> => {
    if (kpiData && menu) {
      return formatKPI(
        kpiData,
        menu,
        domain !== null ? domain : defaultDomain,
        defaultDomain,
      );
    }

    return null;
  },
);

export const selectRationWeightsByName = createSelector(
  [
    kpiDataSelectors.selectData,
    kpiDataSelectors.selectMenu,
    kpiDataSelectors.selectKPITimeFrameDomain,
    kpiDataSelectors.defaultDomain,
  ],
  (
    kpiData,
    menu,
    domain,
    defaultDomain,
  ): Nullable<WeightsByNameYesterdayAndAverageType> => {
    if (kpiData && menu) {
      return formatRationWeightsByName(
        kpiData,
        menu,
        domain !== null ? domain : defaultDomain,
      );
    }

    return null;
  },
);

export const selectKPIActiveKPIData = createSelector(
  kpiDataSelectors.selectData,
  kpiDataSelectors.selectCurrentSelectedKpi,
  (kpiData, currentSelectedKPI) => {
    if (kpiData && currentSelectedKPI) {
      const result: Record<string, Nullable<number>> = {};

      kpiData.forEach((item) => {
        const formattedDate = format(new Date(item.date), 'yyyy-MM-dd');

        const itemValue = getKPIValue[currentSelectedKPI.kpiType](
          item,
          currentSelectedKPI,
        );
        result[formattedDate] = itemValue;
      });

      return result;
    }

    return null;
  },
);

export const getKPIValue: Record<
  KpiType,
  (item: KPIDataType, currentSelectedKPI: SelectedKPI) => any
> = {
  feedType: (item, currentSelectedKPI) => {
    const [feedName, kpi] = currentSelectedKPI.id.split('_');

    if (feedName && kpi && item.feedTypeData) {
      const feedByName = item.feedTypeData.find(
        (feedType) => feedType.feedName === feedName,
      );

      if (feedByName) {
        return feedByName[kpi];
      }
    }

    return null;
  },
  rationWeight: (item, currentSelectedKPI) => {
    const [rationName, feedName, kpi] = currentSelectedKPI.id.split('_');

    if (rationName && feedName && kpi && item.rationData) {
      const rationByName = item.rationData.find(
        (ration) => ration.rationName === rationName,
      );

      if (rationByName) {
        const feedByName = rationByName.weights.find(
          (weight) => weight.feedName === feedName,
        );

        if (feedByName) {
          return feedByName[kpi];
        }
      }
    }

    return null;
  },
  ration: (item, currentSelectedKPI) => {
    const [rationName, kpi] = currentSelectedKPI.id.split('_');

    if (rationName && kpi && item.rationData) {
      const rationByName = item.rationData.find(
        (ration) => ration.rationName === rationName,
      );

      if (rationByName) {
        return rationByName[kpi];
      }
    }

    return null;
  },
  fenceName: (item, currentSelectedKPI) => {
    const [fenceName, kpi] = splitByLastUnderscore(currentSelectedKPI.id);

    if (fenceName && kpi && item.feedFencesData) {
      const fenceByName = item.feedFencesData.find(
        (fence) => fence.fenceName === fenceName,
      );

      if (fenceByName) {
        return fenceByName[kpi];
      }
    }

    return null;
  },
  kpi: (item, currentSelectedKPI) => {
    if (!item.general) return null;
    const itemValue = item.general[currentSelectedKPI.id];
    return itemValue !== undefined
      ? item.general[currentSelectedKPI.id]!
      : null;
  },
};

export function splitByLastUnderscore(str: string) {
  const lastIndex = str.lastIndexOf('_');
  const part1 = str.substring(0, lastIndex);
  const part2 = str.substring(lastIndex + 1);
  return [part1, part2];
}

export const fetchKPIData = createAction<KPIRequestParams>('kpi/fetchKPIData');

export default kpiSlice.reducer;
