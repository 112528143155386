// eslint-disable-next-line import/prefer-default-export
export function isSameItem(currentItem, selectedItem) {
  if (stringifyWithDates(currentItem) === stringifyWithDates(selectedItem)) {
    return true;
  }
  return false;
}

export function stringifyWithDates(obj) {
  return JSON.stringify(obj, (key, value) => {
    return value instanceof Date ? value.toISOString() : value;
  });
}

export const getTypesFromObject = (obj) => {
  return Object.values(obj).flatMap((category) =>
    Object.entries(category?.['members'] || [])
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      .filter(([_, member]) => member?.['selected'])
      .map(([type]) => type),
  );
};

export const selectAllProperties = (obj) => {
  return Object.keys(obj?.eventTypes)?.reduce((acc, typeKey) => {
    acc[typeKey] = {
      ...obj.eventTypes[typeKey],
      members: Object.keys(obj.eventTypes[typeKey].members).reduce(
        (acc2, memberKey) => {
          acc2[memberKey] = {
            ...obj.eventTypes[typeKey].members[memberKey],
            selected: true,
          };
          return acc2;
        },
        {},
      ),
    };
    return acc;
  }, {});
};
