import { TableTypes } from '../../../common/types';

export const GET_VECTOR_CHARTS_CONFIGURATION_REQUEST =
  'GET_VECTOR_CHARTS_CONFIGURATION_REQUEST';
export const GET_VECTOR_CHARTS_CONFIGURATION_REQUEST_SUCCESS =
  'GET_VECTOR_CHARTS_CONFIGURATION_REQUEST_SUCCESS';
export const GET_VECTOR_CHARTS_CONFIGURATION_REQUEST_ERROR =
  'GET_VECTOR_CHARTS_CONFIGURATION_REQUEST_ERROR';
export const GET_VECTOR_CHART_DATA_REQUEST = 'GET_VECTOR_CHART_DATA_REQUEST';
export const GET_VECTOR_CHART_DATA_REQUEST_SUCCESS =
  'GET_VECTOR_CHART_DATA_REQUEST_SUCCESS';
export const GET_VECTOR_CHART_DATA_REQUEST_ERROR =
  'GET_VECTOR_CHART_DATA_REQUEST_ERROR';
export const RESET_VECTOR_CHARTS_DATA = 'RESET_VECTOR_CHARTS_DATA';
export const RESET_VECTOR_CONFIGURATION = 'RESET_VECTOR_CONFIGURATION';

export enum VectorClickedElementType {
  event = 'event',
  chart = 'chart',
  vectorChart = 'vectorChart',
  robotIcon = 'robotIcon',
  criticalAlarmList = 'criticalAlarmList',
  selectionSummation = 'selectionSummation',
}

export type VectorConfigurationInitialState = {
  vectorConfigurationData: VectorConfigurationData | any;
  clickedElementType: VectorClickedElementType | null;
  vectorChartLoaded: boolean;
};

export type AlarmTextTranslation = {
  description: string;
  reason: string;
  technicianSolution: string;
};

export type AlarmText = {
  id: number;
  idHex: string;
  parameters: string[];
  translations: AlarmTextTranslation[];
};

export type ConfigurationEventProperties = {
  color: string;
  icon: string;
  iconClass: string;
  id: number;
  name: string;
};

export type ConfigurationEventTypes = Record<
  string,
  {
    members: Record<string, ConfigurationEventProperties>;
  }
>;

export type VectorConfigurationData = {
  alarmsText: AlarmText[];
  eventTypes: any;
};

export declare module VectorPage {
  type ChartMeasurementConfig = {
    CRANE_MOVING_CONTAINER_BASELINE_Y: number;
    CRANE_MOVING_CONTAINER_BASELINE_ABSOLUTE_Y: number;
    CRANE_MOVING_CONTAINER_HEIGHT: number;
    DEFAULT_ACTIVITY_HEIGHT_IN_PIXELS: number;
    TEN_PIXELS: number;
    FOUR_PIXELS: number;
    FG_EVENTS_BASELINE_Y: number;
    GRAB_CONTAINER_BASELINE_Y: number;
    GRAB_CONTAINER_HEIGHT: number;
    GRAB_CONTAINER_SUBTASK_BASELINE_Y: number;
    GRAB_CONTAINER_SUBTASK_HEIGHT: number;
    GRAB_TASK_FEED_HEIGHT_BASELINE_Y: number;
    GRAB_TASK_FEED_HEIGHT_BASELINE_ABSOLUTE_Y: number;
    GRAB_TASK_FEED_HEIGHT_HEIGHT: number;
    MAX_ACTIVITY_HEIGHT_IN_PIXELS: number;
    MFR1_ACTIVITIES_BASELINE_Y: number;
    MFR1_ACTIVITIES_BASELINE_ABSOLUTE_Y: number;
    MFR1_ACTIVITIES_HEIGHT: number;
    MFR1_EVENTS_BASELINE_Y: number;
    MFR1_EVENTS_HEIGHT: number;
    MFR2_ACTIVITIES_BASELINE_Y: number;
    MFR2_ACTIVITIES_BASELINE_ABSOLUTE_Y: number;
    MFR2_ACTIVITIES_HEIGHT: number;
    MFR2_EVENTS_BASELINE_Y: number;
    MFR2_EVENTS_HEIGHT: number;
    PDB_BASELINE_Y: number;
    PDB_BASELINE_ABSOLUTE_Y: number;
    PDB_COLOR_BASELINE_Y: number;
    PDB_COLOR_HEIGHT: number;
    PDB_EVENTS_BASELINE_Y: number;
    PDB_EVENTS_HEIGHT: number;
    PDB_HEIGHT: number;
  };
}

export type SettingsWithMembers<T, U extends string | number = string> = Record<
  U,
  {
    icon?: string;
    members: Record<string, T>;
  }
>;

export type TableDetailsByType = {
  dispenseWeight: DetailsTableData<DispenseWeightAggregationType>;
  feedGrabs: DetailsTableData<
    FeedGrabsAggregationType,
    ExtendedFeedGrabsAggregationType
  >;
  Height: DetailsTableData<HeightAggregationType>;
  'Dosing Speed': DetailsTableData<DosingAggregationType>;
  Accuracy: DetailsTableData<AccuracyAggregationType>;
};

type DetailsTableData<T, R = T> = (
  data: T[],
  metricPreference: string,
  chartUnitLabel?: string,
  aggregationPropertyLabel?: string,
) => {
  columns: TableTypes.GenericTableRow<R>[];
  aggregatedData: R[];
};

export type HeightAggregationType = {
  rowName: string;
  minValue: number;
  maxValue: number;
  avgValue: number;
};

export type DosingAggregationType = HeightAggregationType;

export type AccuracyAggregationType = {
  rowName: string;
  requestedSum: number;
  loadedSum: number;
  differenceSum: number;
  differenceSumPercentage: string;
};

export type DispenseWeightAggregationType = {
  feedType: string;
  loaded: number;
  avgFlow: number;
};

export type ScanningActivityType = {
  actionId: number;
  displayThreshold: boolean;
  fenceFloorHeight: number;
  fenceId: number;
  height: number;
  invalidPercentage: number;
  note: string;
  scanType: number;
  threshold: number;
  valid: boolean;
};

export type ExtendedScanningActivityType = ScanningActivityType & {
  fence: string;
};

export type FeedGrabsAggregationType = {
  feedType: string;
  estimated: number;
  grabs: number;
  retries: { inPercent: number; numeric: number };
};

export type ExtendedFeedGrabsAggregationType = FeedGrabsAggregationType & {
  retriesNum: number;
  retriesInPercent: number;
  weightGrabRatio: number;
};
