import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';
import {
  AvailableLanguages,
  TranslationSliceInitialState,
  UpdateLanguagePayload,
} from './types/translationTypes';

export const translationInitialState: TranslationSliceInitialState = {
  preferredLanguage: 'en',
  errorMessage: '',
  availableLanguages: [],
};

export const translationSlice = createSlice({
  name: 'translate',
  initialState: translationInitialState,
  reducers: {
    getLanguagesRequestSuccess: (
      state,
      action: PayloadAction<AvailableLanguages[]>,
    ) => {
      state.availableLanguages = action.payload;
      state.errorMessage = '';
    },
    getLanguagesRequestError: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setPreferredLanguage: (state, action: PayloadAction<string>) => {
      state.preferredLanguage = action.payload;
      state.errorMessage = '';
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    updateLanguage: (state, action: PayloadAction<UpdateLanguagePayload>) => {
      state.errorMessage = '';
    },
    updateLanguageRequestError: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
  },
});

export const getLanguages = createAction('GET_LANGUAGES_REQUEST');

export const {
  getLanguagesRequestSuccess,
  getLanguagesRequestError,
  setPreferredLanguage,
  updateLanguage,
  updateLanguageRequestError,
} = translationSlice.actions;

// selectors
export const translationSelectors = {
  selectPreferredLanguages: (state: RootState) =>
    state.translate.preferredLanguage,
  selectErrorMessage: (state: RootState) => state.translate.errorMessage,
  selectAvailableLanguages: (state: RootState) =>
    state.translate.availableLanguages,
};

export default translationSlice.reducer;
