import { useEffect, useState } from 'react';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../reducers/rootReducer';
import { selectInterceptorsStatus } from '../../reducers/interceptorsSlice';
import { getLanguages } from '../../reducers/translationSlice';

function useTabVisibility() {
  const dispatch = useDispatch();
  const interceptorsStatus = useAppSelector(selectInterceptorsStatus);

  // Initialize the state with the current visibility state
  const [visibilityState, setVisibilityState] = useState(
    document.visibilityState,
  );

  // Define a function to handle the visibility change event
  function handleVisibilityChange() {
    setVisibilityState(document.visibilityState);
  }

  // Add the event listener when the component mounts
  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (visibilityState && interceptorsStatus === 'initialized') {
      const token = localStorage.getItem('access_token');
      if (token) {
        const decoded = jwtDecode<JwtPayload>(token);
        const currentTime = Date.now() / 1000;
        if (decoded?.exp && decoded.exp < currentTime) {
          // Token expired, calls getLanguages to refresh the token. The refresh token is handled in the axios instance with interceptors.
          // If the token is not refreshed, the user will be redirected to the login page.
          // getLanguages it is used because it is a light weight API call.
          dispatch(getLanguages());
        }
      }
    }
  }, [visibilityState, interceptorsStatus]);
}

export default useTabVisibility;
