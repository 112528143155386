import { call, put, take } from 'redux-saga/effects';
import { KPIDataApiType } from '../../api/lelyBackend/kpiDataApi';
import {
  fetchKPIData,
  setKPIData,
  setKPIError,
} from '../../reducers/kpiDataSlice';
import { getLoadId } from '../../common/redux/loadManager/methods';
import {
  setEndLoading,
  setStartLoading,
} from '../../common/redux/loadManager/actions';
import { KPIRequestParams } from '../../reducers/types/kpiDataTypes';
import { AwaitedApiResultType } from '../../common/types';

let currentLoadId = 0;

export default function* kpiSaga(api: KPIDataApiType) {
  while (true) {
    const kpiAction = yield take(fetchKPIData.type);
    currentLoadId = getLoadId();

    yield put(setStartLoading(currentLoadId));
    yield call(getKPIDataFromApi, api, kpiAction.payload);
  }
}

function* getKPIDataFromApi(api: KPIDataApiType, params: KPIRequestParams) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const entitySettingsData: AwaitedApiResultType<typeof api.getKPIData> =
      yield call(api.getKPIData, params);

    yield put(
      setKPIData({
        ...entitySettingsData.data,
        defaultDomain: [params.fromDate, params.toDate],
      }),
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error: any) {
    yield put(setKPIError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
