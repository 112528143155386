import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isAfter, isBefore } from 'date-fns';
import { cloneDeep, isEmpty } from 'lodash';
import {
  CurrentTypedInput,
  DetailsInterface,
  EventDetailsInitialInterface,
  EventsButtonType,
  SearchInput,
  SelectedEventDetails,
} from '../containers/EventsPage/types';
import { RootState } from './rootReducer';
import { EventWithId, Event } from '../containers/EventsPageVDE/types';
import {
  getAlarmDefinitionByAlarmCode,
  getAlarmDescriptionWithParametersFilledIn,
  getEventTypeToCategoryDictionary,
} from '../common/data/vector_chart_data_modifier';
import { EMPTY_CHECK_BOX_LEVELS_ERROR_MESSAGE } from '../common/constants';
import { getTypesFromObject } from '../components/EventsPageTableVDE/utils';

export const eventDetailsInitialState: EventDetailsInitialInterface = {
  eventPageDetails: [],
  vdeEvents: [],
  isEventDetailsError: false,
  errorMessage: '',
  selectedEventDetails: null,
  lastPreferredEventFilter: null,
  numberOfResults: 0,
  latestOffset: 0,
  extendedDates: { start: null, end: null },
  maxRowsPerPage: 200,
  buttonsDisabledState: {
    preceding: false,
    succeeding: false,
    fullContext: false,
  },
  filteredEventsByType: [],
  checkboxTypeError: null,
  searchInputValue: null,
  isContextSelected: false,
  latestAppliedFilters: { appliedTypes: [], appliedSearchText: null },
  startTime: '',
  endTime: '',
  isLoadingEvents: false,
  isLoadingMoreEvents: {
    succeeding: false,
    preceding: false,
    fullContext: false,
  },
  isLoadingEventDetails: false,
};

export const eventDetailsSlice = createSlice({
  name: 'eventDetails',
  initialState: eventDetailsInitialState,
  reducers: {
    setLoadingEvents: (state, action: PayloadAction<boolean>) => {
      state.isLoadingEvents = action.payload;
    },
    setLoadingMoreEvents: (
      state,
      action: PayloadAction<{ isLoading: boolean; type: EventsButtonType }>,
    ) => {
      state.isLoadingMoreEvents[action.payload.type] = action.payload.isLoading;
    },
    setLoadingEventDetails: (state, action: PayloadAction<boolean>) => {
      state.isLoadingEventDetails = action.payload;
    },
    getEventDetailsSuccess: (state, action) => {
      state.eventPageDetails = action.payload.events;
      state.numberOfResults = action.payload.totalCount;
      if (action.payload.totalCount < state.latestOffset) {
        state.latestOffset = 0;
      }
      state.isEventDetailsError = false;
      state.errorMessage = '';
      state.buttonsDisabledState = {
        preceding: false,
        succeeding: false,
        fullContext: false,
      };
      state.isContextSelected = false;
    },
    getEventDetailsError: (state, action) => {
      state.errorMessage = action.payload;
      state.eventPageDetails = [];
      state.isEventDetailsError = true;
      state.selectedEventDetails = null;
    },
    getDetailsForSelectedEventSuccess: (state, action) => {
      state.selectedEventDetails = action.payload;
    },
    selectedVectorEventsDetails: (state, action) => {
      const vdeEvents = cloneDeep(state.vdeEvents);
      const event = action.payload;
      const filteredEvent = vdeEvents.filter((x) => {
        return (
          new Date(x?.time).getTime() === new Date(event?.time).getTime() &&
          x?.name === event?.name
        );
      });
      state.selectedEventDetails = { ...filteredEvent?.[0], ...event };
    },
    getDetailsForSelectedEventError: (state) => {
      state.selectedEventDetails = null;
    },
    setPreferredEventFilters: (state, action) => {
      state.lastPreferredEventFilter = { ...action.payload };
    },
    resetPreferredEventFilters: (state) => {
      state.lastPreferredEventFilter = null;
    },
    resetSelectedEventDetails: (state) => {
      state.selectedEventDetails = null;
    },
    setLatestOffset: (state, action: PayloadAction<number>) => {
      state.latestOffset = action.payload;
    },
    setLatestAppliedFilters: (
      state,
      action: PayloadAction<{
        namesOfSelectedTypes?: any[];
        modifiedSearchParams?:
          | Record<string, string>
          | CurrentTypedInput
          | null;
      }>,
    ) => {
      const { namesOfSelectedTypes, modifiedSearchParams } = action.payload;
      if (namesOfSelectedTypes !== undefined) {
        state.filteredEventsByType = namesOfSelectedTypes;
        state.latestAppliedFilters.appliedTypes = namesOfSelectedTypes;
      }
      if (modifiedSearchParams !== undefined) {
        state.searchInputValue = modifiedSearchParams;
        state.latestAppliedFilters.appliedSearchText = modifiedSearchParams;
      }
    },
    extendEventDetails: (
      state,
      action: PayloadAction<{
        events: DetailsInterface[];
        type: EventsButtonType;
        item?: string | null;
      }>,
    ) => {
      const { events, type, item } = action.payload;

      if (type === EventsButtonType.Context) {
        state.numberOfResults = events.length;
        state.eventPageDetails = events;
        state.extendedDates = {
          start: events[events.length - 1].eventTimestamp,
          end: events[0].eventTimestamp,
        };
        const idexOfSelected = events.findIndex((e) => e.id === item);
        if (idexOfSelected !== 10 && idexOfSelected !== -1) {
          const selectedEvent = events[idexOfSelected];
          events.splice(idexOfSelected, 1);
          events.splice(10, 0, selectedEvent);
        }
        state.buttonsDisabledState.preceding = false;
        state.buttonsDisabledState.succeeding = false;
        state.latestOffset = 0;
        state.isContextSelected = true;
        return;
      }

      state.numberOfResults += events.length;
      state.eventPageDetails =
        type === EventsButtonType.Preceding
          ? [...state.eventPageDetails]
              .concat(events)
              .slice(0, state.maxRowsPerPage)
          : events
              .concat([...state.eventPageDetails])
              .slice(0, state.maxRowsPerPage);

      if (events.length) {
        state.extendedDates =
          type === EventsButtonType.Preceding
            ? {
                ...state.extendedDates,
                start: events[events.length - 1].eventTimestamp,
              }
            : {
                ...state.extendedDates,
                end: events[0].eventTimestamp,
              };
      }
    },
    updateButtonState: (
      state,
      action: PayloadAction<{
        eventsLength: number;
        type: EventsButtonType;
        offset: number;
      }>,
    ) => {
      const { eventsLength, type, offset } = action.payload;
      if (!eventsLength || eventsLength < offset - 1) {
        state.buttonsDisabledState[type] = true;
      }
    },
    setExtendedDates: (state, action) => {
      const dates = cloneDeep(state.extendedDates);
      state.extendedDates = { ...dates, ...action.payload };
    },
    setFilteredEventsByType: (
      state,
      action: PayloadAction<{
        isVE?: boolean;
        types: any;
        totalTypesLength?: number;
      }>,
    ) => {
      if (action.payload.isVE) {
        state.filteredEventsByType = action.payload.types;
        return;
      }
      const selectedTypes = [...action.payload.types];
      if (selectedTypes.length === action.payload.totalTypesLength) {
        selectedTypes.push('SHOW ALL');
      }
      state.filteredEventsByType = selectedTypes;
      if (selectedTypes?.length < 1) {
        state.checkboxTypeError = EMPTY_CHECK_BOX_LEVELS_ERROR_MESSAGE;
      } else {
        state.checkboxTypeError = null;
      }
    },

    setCheckboxTypeError: (state, action) => {
      state.checkboxTypeError = action.payload;
    },
    setSearchInputValue: (state, action) => {
      state.searchInputValue = action.payload;
    },
    setIsContextSelected: (state, action) => {
      state.isContextSelected = action.payload;
    },
    setStartEndDate: (state, action) => {
      state.startTime = action.payload.start;
      state.endTime = action.payload.end;
    },
    setVdeEvents: (
      state,
      action: PayloadAction<{ events: Event[]; vectorConfigurationData: any }>,
    ) => {
      const { events, vectorConfigurationData } = action.payload;
      const extendedDates = cloneDeep(state.extendedDates);
      if (extendedDates.start) {
        const filteredEventsByHour = events.filter((x) => {
          return (
            isBefore(x.time, extendedDates.end!) &&
            isAfter(x.time, extendedDates.start!)
          );
        });
        state.vdeEvents = transformEvents(
          filteredEventsByHour,
          vectorConfigurationData,
        );
      } else {
        state.vdeEvents = transformEvents(events, vectorConfigurationData);
      }
    },
    resetVdeEvents: (state) => {
      state.vdeEvents = [];
      state.extendedDates = { start: null, end: null };
    },
  },
});

export const {
  setLoadingEvents,
  setLoadingMoreEvents,
  setLoadingEventDetails,
  getEventDetailsSuccess,
  getEventDetailsError,
  getDetailsForSelectedEventSuccess,
  getDetailsForSelectedEventError,
  setPreferredEventFilters,
  resetPreferredEventFilters,
  resetSelectedEventDetails,
  setLatestOffset,
  setLatestAppliedFilters,
  extendEventDetails,
  setExtendedDates,
  updateButtonState,
  setFilteredEventsByType,
  setCheckboxTypeError,
  setSearchInputValue,
  setIsContextSelected,
  selectedVectorEventsDetails,
  setStartEndDate,
  setVdeEvents,
  resetVdeEvents,
} = eventDetailsSlice.actions;

export const getEventDetailsForEntityNew = createAction<{
  entityId: string;
  startDateToTimestamp: string;
  endDateToTimestamp: string;
  limit: number;
}>('GET_EVENT_DETAILS_REQUEST_NEW');

export const getVdeEvents = createAction<{
  entityId: string;
  startDate: string;
  endDate: string;
  type?: EventsButtonType;
}>('GET_EVENT_FOR_VDE');

export const getSelectedEventDetails = createAction<{
  entityId: string;
  eventData: DetailsInterface;
}>('GET_DETAILS_FOR_SELECTED_EVENT_REQUEST_NEW');

export const fetchMoreEvents = createAction<{
  entityId: string;
  type: EventsButtonType;
  limit?: number;
  item?: SelectedEventDetails | DetailsInterface;
}>('FETCH_MORE_EVENTS');

export const eventDetailsSelectors = {
  isLoadingEvents: (state: RootState) => state.eventPageDetails.isLoadingEvents,
  isLoadingMoreEvents: (state: RootState) =>
    state.eventPageDetails.isLoadingMoreEvents,
  isLoadingEventDetails: (state: RootState) =>
    state.eventPageDetails.isLoadingEventDetails,

  selectedEventDetails: (state: RootState) =>
    state.eventPageDetails.selectedEventDetails,
  vdeEvents: (state: RootState) => state.eventPageDetails.vdeEvents,
  selectVdeEvents: (state: RootState) => {
    const { vdeEvents, searchInputValue, filteredEventsByType } =
      state.eventPageDetails;

    const selectedTypes = getTypesFromObject(filteredEventsByType);

    const filteredEvents = vdeEvents.filter((event) => {
      let typeMatch = false;

      if (selectedTypes?.includes(event.type)) {
        typeMatch = true;
      }

      // Search filtering
      let searchMatch = true;
      if (!isEmpty(searchInputValue)) {
        const search = searchInputValue as SearchInput;

        if (search.description) {
          const description = search.description.toLowerCase();
          const eventName = event.translatedName || event.name;
          searchMatch =
            searchMatch && eventName?.toLowerCase().includes(description);
        }

        if (search.device) {
          const device = search.device.toLowerCase();
          searchMatch =
            searchMatch && event.device?.toLowerCase().includes(device);
        }

        if (search.id) {
          const id = search.id.toLowerCase();
          searchMatch = searchMatch && event.code?.toLowerCase().includes(id);
        }

        if (!search.id && !search.device && !search.description) {
          const description = search[0].toLowerCase();
          const eventName = event.translatedName || event.name;
          searchMatch =
            searchMatch && eventName?.toLowerCase().includes(description);
        }
      }

      return typeMatch && searchMatch;
    });
    return filteredEvents;
  },
  eventDetails: (state: RootState) => state.eventPageDetails.eventPageDetails,
  numberOfResults: (state: RootState) => state.eventPageDetails.numberOfResults,
  lastPreferredEventFilter: (state: RootState) =>
    state.eventPageDetails.lastPreferredEventFilter,
  latestOffset: (state: RootState) => state.eventPageDetails.latestOffset,
  maxRowsPerPage: (state: RootState) => state.eventPageDetails.maxRowsPerPage,
  extendedDates: (state: RootState) => state.eventPageDetails.extendedDates,
  buttonsDisabledState: (state: RootState, type: EventsButtonType) =>
    state.eventPageDetails.buttonsDisabledState[type],
  filteredEventsByType: (state: RootState) =>
    state.eventPageDetails.filteredEventsByType,
  checkboxTypeError: (state: RootState) =>
    state.eventPageDetails.checkboxTypeError,
  searchInputValue: (state: RootState) =>
    state.eventPageDetails.searchInputValue,
  isContextSelected: (state: RootState) =>
    state.eventPageDetails.isContextSelected,
  latestAppliedFilters: (state: RootState) =>
    state.eventPageDetails.latestAppliedFilters,
};

export const getNestedProperty = (obj: any, path: string): any => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};
// Helper function to convert a number to hexadecimal string
export const toHexString = (num: number | undefined): string => {
  if (num === undefined) return '';
  return `0x${num.toString(16)}`;
};

// vectorConfigurationData is any type, since it is a temp solution, once the feedNames are extracted from the vectorSettings into separate request then this method will be done properly.
export function transformEvents(
  events: Event[],
  vectorConfigurationData,
): EventWithId[] {
  return events.map((event) => {
    const alarmCode = getNestedProperty(event, 'activity.alarmCode');
    const activity = {
      ...event,
      code: toHexString(alarmCode),
    };
    const dictionary = getEventTypeToCategoryDictionary(
      vectorConfigurationData.eventTypes,
    );
    const currentEventType = event.type;
    const currentEventCategory = dictionary[currentEventType];
    const { iconClass, color } =
      vectorConfigurationData.eventTypes[currentEventCategory]?.members[
        currentEventType
      ] || '';

    let description = activity.name;
    if (
      activity.type === 'CriticalAlarm' ||
      activity.type === 'NonCriticalAlarm'
    ) {
      const alarmDefinitionDetails = getAlarmDefinitionByAlarmCode(
        activity?.code,
        vectorConfigurationData?.alarmsTexts,
      );
      description = getAlarmDescriptionWithParametersFilledIn(
        activity,
        alarmDefinitionDetails,
        vectorConfigurationData,
      );
    }

    return {
      ...activity,
      translatedName: description,
      iconClass,
      iconColor: color,
    };
  });
}

export default eventDetailsSlice.reducer;
