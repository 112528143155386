import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { SelectedActivityType } from '../types';

export default function splitKeyToPlainText(text: string): string {
  return text
    .split(/(?=[A-Z])/)
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ');
}

export function splitToPlainText(text: string): string {
  const words = text.split(/(?=[A-Z])/).map((w) => w.toLowerCase());

  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);

  return words.join(' ');
}

export function convertKeysToCapitalizedText(object: SelectedActivityType) {
  const result = {} as SelectedActivityType;

  Object.keys(object).forEach((k) => {
    const currentValue = object[k];
    if (typeof currentValue === 'object' && !moment.isDate(currentValue)) {
      Object.keys(currentValue).forEach((innerKey) => {
        result[splitKeyToPlainText(innerKey)] = currentValue[innerKey];
      });
    } else {
      result[splitKeyToPlainText(k)] = object[k];
    }
  });
  return !isEmpty(result) ? result : null;
}

export const durationBetweenDates = (date1, date2) => {
  return Math.floor(date1.getTime() - date2.getTime()) / 1000;
};

export const secondsToMinutes = (seconds) => {
  return Math.floor(seconds / 60) % 60;
};

export const secondsToHours = (seconds) => {
  return Math.floor(seconds / 3600);
};

export const secondsToRemainderSeconds = (seconds) => {
  return seconds % 60;
};

export const getFormattedDuration = (durationInSeconds) => {
  const activityDurationInHours = secondsToHours(durationInSeconds);
  let formattedHours = '';
  if (activityDurationInHours > 0) {
    formattedHours = `${activityDurationInHours}h `;
  }
  const activityDurationInMinutes = secondsToMinutes(durationInSeconds);
  let formattedMinuts = '';
  if (
    activityDurationInHours > 0 ||
    (activityDurationInHours === 0 && activityDurationInMinutes > 0)
  ) {
    formattedMinuts = `${activityDurationInMinutes}m `;
  }
  const activityDurationInSeconds =
    secondsToRemainderSeconds(durationInSeconds);
  return `${
    formattedHours + formattedMinuts + activityDurationInSeconds.toFixed(1)
  }s`;
};
