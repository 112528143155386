import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ForbiddenPage from '../../containers/ForbiddenPage/ForbiddenPage';
import LoginPage from '../../containers/LoginPage/LoginPage';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';
import {
  ADE_ONLINE_UPDATES_FEATURE_FLAG,
  DIAGNOSTIC_PAGE,
} from '../../common/constants';
import { featureFlagsSelectors } from '../../reducers/featureFlagSlice';
import { useAppSelector } from '../../reducers/rootReducer';
import RoutesProtector from './RoutesProtector';

const AstronautDataExplorerPageComponent = React.lazy(
  () =>
    import(
      '../../containers/AstronautDataExplorerPage/AstronautDataExplorerPage'
    ),
);
const VectorPageWrapperComponent = React.lazy(
  () => import('../../containers/VectorPage/VectorPageWrapper'),
);
const EventsPageDetailsComponent = React.lazy(
  () => import('../../containers/EventsPage/EventDetails'),
);
const EventsPageDetailsVDEComponent = React.lazy(
  () => import('../../containers/EventsPageVDE/EventDetails'),
);
const BackupSettingsComponent = React.lazy(
  () => import('../../containers/BackupSettings/BackupSettings'),
);
const SettingsComponent = React.lazy(
  () => import('../../containers/Settings/Settings'),
);
const FarmsPageComponent = React.lazy(
  () => import('../../containers/FarmsPage/FarmsPage'),
);
const FarmDetailsComponent = React.lazy(
  () => import('../FarmDetails/FarmDetails'),
);
const EntityDetailsComponent = React.lazy(
  () => import('../EntityDetails/EntityDetails'),
);
const LogsPageComponent = React.lazy(
  () => import('../../containers/LogsPage/LogsPage'),
);
const AMFSettingsComponent = React.lazy(
  () => import('../../containers/AMFSettings/AMFSettings'),
);
const FarmItSettingsComponent = React.lazy(
  () => import('../../containers/FarmITSettings/FarmItSettings'),
);
const CanbusPageComponent = React.lazy(
  () => import('../../containers/CanbusPage/CanbusPage'),
);
const UpdatesDetailsComponent = React.lazy(
  () => import('../UpdatesDetails/UpdatesDetails'),
);

const PerformanceIndicatorsPageComponent = React.lazy(
  () =>
    import(
      '../../containers/PerformanceIndicatorsPage/PerformanceIndicatorsPage'
    ),
);

function TopLevelRouter() {
  const isActiveUpdates = useAppSelector((state) =>
    featureFlagsSelectors.isFlagActive(state, ADE_ONLINE_UPDATES_FEATURE_FLAG),
  );
  const isDiagnosticPageActive = useAppSelector((state) =>
    featureFlagsSelectors.isFlagActive(state, DIAGNOSTIC_PAGE),
  );

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route element={<RoutesProtector />}>
        <Route path="/farms">
          <Route index element={<FarmsPageComponent />} />
          <Route path=":farmId" element={<FarmDetailsComponent />} />
        </Route>
        <Route
          path="/entities/:externalId/overview"
          element={<EntityDetailsComponent />}
        />
        <Route
          path="/entities/:externalId/events"
          element={<EventsPageDetailsComponent />}
        />
        <Route
          path="/entities/:externalId/vde-events"
          element={<EventsPageDetailsVDEComponent />}
        />
        <Route
          path="/entities/:externalId/logs"
          element={<LogsPageComponent />}
        />
        <Route
          path="/entities/:externalId/settings"
          element={<SettingsComponent />}
        />
        <Route
          path="/entities/:externalId/settings/backups"
          element={<BackupSettingsComponent />}
        />
        <Route
          path="/entities/:externalId/AMF-settings"
          element={<AMFSettingsComponent />}
        />
        <Route
          path="/entities/:externalId/FarmIT-settings"
          element={<FarmItSettingsComponent />}
        />
        {isDiagnosticPageActive && (
          <Route
            path="/entities/:externalId/domains/canbus"
            element={<CanbusPageComponent />}
          />
        )}
        <Route
          path="/entities/:externalId/Data_Explorer"
          element={<AstronautDataExplorerPageComponent />}
        />
        {isActiveUpdates && (
          <Route
            path="/entities/:externalId/updates"
            element={<UpdatesDetailsComponent />}
          />
        )}
        <Route
          path="/entities/:externalId/Performance_Indicators"
          element={<PerformanceIndicatorsPageComponent />}
        />
        <Route
          path="/entities/:externalId/Vector_Data_Explorer"
          element={<VectorPageWrapperComponent />}
        />
        <Route path="/login?isTest=true" element={<LoginPage />} />
        <Route path="/farms?isTest=true" element={<FarmsPageComponent />} />
        <Route
          path="/entities/:externalId/overview?isTest=true"
          element={<EventsPageDetailsComponent />}
        />
        <Route
          path="/entities/:externalId/settings?isTest=true"
          element={<SettingsComponent />}
        />
        <Route
          path="/entities/:externalId/settings/backups?isTest=true"
          element={<BackupSettingsComponent />}
        />
        <Route
          path="/entities/:externalId/domains/canbus?isTest=true"
          element={<CanbusPageComponent />}
        />
        <Route
          path="/entities/:externalId/Vector_Data_Explorer?isTest=true"
          element={<VectorPageWrapperComponent />}
        />
      </Route>
      <Route path="/forbidden" element={<ForbiddenPage />} />
      <Route path="/notFound" element={<NotFoundPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default TopLevelRouter;
