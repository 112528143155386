import React, { Suspense } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { useAppSelector } from '../../reducers/rootReducer';
import Preloader from '../Preloader/Preloader';

export default function RoutesProtector() {
  const { isLoggedIn } = useAppSelector((state) => state.userSession);
  const isAzureAuthenticated = useIsAuthenticated();
  const location = useLocation();

  // Skip protection for auth-redirect route
  if (location.pathname === '/auth-redirect') {
    return <Outlet />;
  }

  // Allow access if either auth method is valid
  return isLoggedIn || isAzureAuthenticated ? (
    <Suspense fallback={<Preloader />}>
      <Outlet />
    </Suspense>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}
